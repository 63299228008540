import { defineStore } from 'pinia'
import * as Sentry from '@sentry/nuxt'
import api from '~/api'
import type { AccountDTO } from '~/types/account'

export const useMyAccountStore = defineStore({
  id: 'myAccountStore',
  state: () => ({
    accountInformations: null
  }),
  actions: {
    async getAccountInformations() {
      try {
        const { data, error } = await useAPI(api.getAccountInformations())
        if (error.value) throw error.value

        const res: AccountDTO = data.value
        this.accountInformations = res
        Sentry.setUser({ email: res.email })
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  }
})
